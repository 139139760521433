<template>
  <v-container fluid fill-height class="ma-0 pa-0">
    <!-- <v-btn id="btn_exit" elevation="2" fab small @click="exit()">
      <v-icon>mdi-close-outline</v-icon>
    </v-btn> -->
    <v-row>
      <v-col>
        <div v-if="step == 1">
          <v-container fill-height fluid>
            <v-row justify="space-around">
              <v-col class="d-flex justify-center">
                <v-card color="#51464c" class="myCard" elevation="0">
                  <v-card-title class="justify-center">
                    OneBook 遙控器</v-card-title
                  >
                  <v-card-actions class="qrCodeFrame">
                    <v-responsive :aspect-ratio="1 / 1">
                      <qrcode-vue
                        class="qrCodeElement"
                        :value="qrcodeData"
                        level="H"
                        renderAs="svg"
                        size="100%"
                      ></qrcode-vue>
                    </v-responsive>
                  </v-card-actions>

                  <v-card-subtitle class="text-center"
                    >請用手機或平板掃描QR Code</v-card-subtitle
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-if="step == 2">
          <v-container fill-height fluid>
            <v-row justify="space-around">
              <v-col class="d-flex justify-center">
                <v-card color="#51464c" class="myCard" elevation="0">
                  <v-card-title class="justify-center">
                    OneBook 遙控器</v-card-title
                  >
                  <v-card-actions class="justify-center">
                    <v-icon
                      aria-hidden="false"
                      color="green"
                      style="width: 100%; font-size: 100px"
                    >
                      mdi-access-point-check
                    </v-icon>
                  </v-card-actions>

                  <v-card-subtitle class="text-center"
                    >連結成功！請用行動裝置選擇功能</v-card-subtitle
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-show="step == 3">
          <v-layout justify-center align-center>
            <video ref="myVideo"></video>
          </v-layout>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { io } from "socket.io-client";
import { v1 as uuidv1 } from "uuid";
import QrcodeVue from "qrcode.vue";
import { Projection } from "../common/meeting";
// @ is an alias to /src
export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      step: 1,
      remoteId: "",
      bookId: "",
      roomId: "",
      userName: "",
      role: "",
      userId: "",
      controller: "",
      mySocket: null,
      site: "demo",
    };
  },
  computed: {
    qrcodeData: function() {
      let url = "";
      // if (process.env.NODE_ENV === "development")
      //   url = `${process.env.VUE_APP_ONEBOOK_URL}/Sender?remoteId=${this.remoteId}`;
      // else
      console.log(document.referrer);
      let siteUrl = "";
      if (
        document.referrer &&
        document.referrer.includes("reader-uat.oneclass.com.tw")
      ) {
        siteUrl = process.env.VUE_APP_ONEBOOK_URL.replace(
          "reader-dev.oneclass.com.tw",
          "reader-uat.oneclass.com.tw"
        );
      } else if (this.site === "onebook") {
        console.log(document.referrer);
        siteUrl = process.env.VUE_APP_ONEBOOK_URL;
      } else {
        siteUrl = process.env.VUE_APP_ONEBOOK_Demo_URL;
      }
      url = `${siteUrl}/${this.bookId}?roomId=${this.roomId}&userName=${this.userName}&role=${this.role}&id=${this.userId}&remoteId=${this.remoteId}`;
      console.log(url);
      return url;
    },
  },
  created() {},
  mounted() {
    this.remoteId = uuidv1();
    this.bookId = this.$route.query.bookId;
    this.roomId = this.$route.query.roomId;
    this.userName = this.$route.query.userName;
    this.site = this.$route.query.site;
    this.role = this.$route.query.role;
    this.userId = this.$route.query.id;
    this.apiUrl = process.env.VUE_APP_API_URL;
    let socketUrl = process.env.VUE_APP_SOCKET_URL;
    this.mySocket = io(socketUrl, {
      path: "/socket",
      transports: ["websocket"],
      query: {
        remoteId: this.remoteId,
      },
    });

    this.mySocket.on("onConnection", () => {
      this.mySocket.emit("joinRemote", "");
      console.log("my connection");
    });

    this.mySocket.on("onReady", () => {
      this.toReady();
      this.$sendPostMessage("OnConnection");
    });

    this.mySocket.on("openProjection", (data) => {
      this.openVideo(data.meetingId);
    });

    this.mySocket.on("exitProjection", () => {
      this.toStart();
      this.$sendPostMessage("HideWindow");
    });

    this.mySocket.on("otherDisconnect", () => {
      this.toStart();
      this.$sendPostMessage("Disconnect");
    });

    window.addEventListener("message", this.toStart(), false);
  },
  methods: {
    async exit() {
      this.$sendPostMessage("HideWindow");
    },
    async openVideo(meetingId) {
      this.step = 3;
      this.projection = new Projection({
        apiUrl: this.apiUrl,
        videoElement: this.$refs.myVideo,
      });
      let deviceErrorHandler = (errorCode) => {
        this.showError(`目前此裝置無法使用：錯誤碼 ${errorCode}`);
      };
      this.projection.SetDeviceErrorHandler(deviceErrorHandler);
      await this.projection.Recevie(meetingId);
    },
    async RecycleProjection() {
      if (!!this.projection) {
        this.projection.Leave();
        this.mySocket.emit("exitProjection", "");
        this.projection = null;
      }
    },
    async toStart() {
      await this.RecycleProjection();
      this.step = 1;
    },
    async toReady() {
      if (this.step != 2) this.step = 2;
    },
  },
};
</script>
<style scoped>
#btn_exit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}
.myCard {
  margin: 10px;
  padding: 10px;
  max-width: 300px;
}
.qrCodeFrame {
  position: relative;
  border: 4px solid #ec7963;
  max-height: 250px;
  max-width: 250px;
  width: 100%;
  border-radius: 12px;
  margin: auto;
}
.qrCodeElement {
  z-index: 2;
  position: absolute;
  background: white;
  padding: 10px;
}
.qrCodeFrame::before {
  content: "";
  position: absolute;
  height: calc(100% + 20px);
  width: 50%;
  background-color: #51464c;
  top: -10px;
  left: 25%;
}

.qrCodeFrame::after {
  content: "";
  position: absolute;
  height: 50%;
  width: calc(100% + 20px);
  background-color: #51464c;
  top: 25%;
  left: -10px;
}
video {
  width: 100vw;
  height: 100vh;
}
</style>

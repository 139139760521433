<template>
  <v-card class="mx-auto" max-width="344">
    <v-card-text>
      <video
        class="video-js vjs-default-skin vjs-big-play-centered mx-0 vjs-16-9"
        ref="videoPlayer"
        controls
        muted
        max-width="344"
      />
      <div>{{ this.record.roomId }}</div>
      <v-chip small>{{ this.reocrdTime }}</v-chip>
    </v-card-text>
    <v-divider></v-divider>
    <!-- <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="orange" text @click="stopRecord">停止錄影</v-btn>
    </v-card-actions> -->
  </v-card>
</template>

<script>
import videojs from "video.js";
import axios from "axios";
// @ is an alias to /src
export default {
  components: {},
  props: {
    record: Object,
  },
  data() {
    return {
      player: null,
    };
  },
  computed: {
    // 计算属性的 getter
    reocrdTime: function () {
      const time = new Date(this.record.startTime);
      const minuteStr = String(time.getMinutes()).padStart(2, "0");
      return `${time.getHours()}:${minuteStr}`;
    },
  },

  created() {},
  async mounted() {
    this.player = videojs(this.$refs.videoPlayer, { liveui: true });
    this.player.ready(() => {
      this.player.play();
    });
    await this.tryGetHLS();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    playHLS(mediaUrl) {
      this.player.src({ src: mediaUrl });
    },
    async tryGetHLS() {
      await this.getHLSUrl()
        .then((res) => {
          this.playHLS(res);
        })
        .catch((err) => {
          setTimeout(this.tryGetHLS, 5000);
        });
    },
    getHLSUrl() {
      return new Promise(async (resolve, reject) => {
        const url = `https://4ic5gz9bzk.execute-api.ap-northeast-1.amazonaws.com/Prod/agora/get_media_url?roomId=${this.record.roomId}`;
        try {
          const response = await axios.get(url);
          resolve(response.data.hls);
        } catch (err) {
          reject(err);
        }
      });
    },
    async stopRecord() {
      var yes = confirm(`是否要停止錄影${this.record.roomId}?`);

      if (yes) {
        const url = `https://4ic5gz9bzk.execute-api.ap-northeast-1.amazonaws.com/Prod/agora/stop_record`;
        const response = await axios.post(url, {
          roomId: this.record.roomId,
        });
      }
    },
    countdown() {
      this.endTime = new Date();
    },
  },
};
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
}
</style>

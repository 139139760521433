<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row></v-row>
    <v-row>
      <v-col class="col-1"> </v-col>
      <v-col> 房間號:{{ this.roomId }} </v-col>
      <v-col class="col-1"><v-btn @click="OnInsertImg">插入圖片</v-btn></v-col>
      <v-col class="col-1"><v-btn @click="OnScreenshot">截圖</v-btn></v-col>
      <v-col class="col-1"><v-btn @click="OnReplay">回放</v-btn></v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <div class="replayboard" ref="replayboard" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <div class="whiteboard" ref="myboard" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// @ is an alias to /src
export default {
  name: "Hls",
  components: {},
  data() {
    return {
      roomId: "",
      boardInfo: {},
      room: null,
      whiteWebSdk: null,
    };
  },
  created() {},
  async mounted() {
    this.roomId = this.$route.params.roomId;
    if (!this.roomId) this.roomId = uuidv4().replace(/-/g, "");
    await this.join();
  },
  beforeDestroy() {
    // if (this.player) {
    //   this.player.dispose();
    // }
  },
  computed: {
    apiUrl: function () {
      if (process.env.NODE_ENV === "development")
        return `https://9q1rtjdc0j.execute-api.ap-northeast-1.amazonaws.com/Prod/whiteboard`;
      else
        return `https://4ic5gz9bzk.execute-api.ap-northeast-1.amazonaws.com/Prod/whiteboard`;
    },
  },
  methods: {
    async join() {
      var WhiteWebSdk = window.WhiteWebSdk;
      this.boardInfo = await this.getBoardInfo();
      var whiteWebSdk = new WhiteWebSdk({
        appIdentifier: this.boardInfo.appIdentifier,
        region: this.boardInfo.boardRegion,
      });
      this.whiteWebSdk = whiteWebSdk;
      console.info(this.boardInfo.appIdentifier);
      console.info(this.boardInfo.boardRegion);
      var joinRoomParams = {
        uuid: this.boardInfo.boardId,
        roomToken: this.boardInfo.boardToken,
      };
      console.info(joinRoomParams);

      whiteWebSdk
        .joinRoom(joinRoomParams)
        .then((room) => {
          this.room = room;
          console.info(room);
          console.info(this.$refs.myboard);
          room.bindHtmlElement(this.$refs.myboard);
        })
        .catch(function (err) {
          console.error(err);
        });
    },
    async OnScreenshot() {
      return new Promise(async (resolve, reject) => {
        const headers = {
          token: this.boardInfo.boardToken,
          region: "cn-hz",
        };
        const url = `https://api.netless.link/v5/rooms/${this.boardInfo.boardId}/screenshots`;
        try {
          const response = await axios.post(
            url,
            {
              width: 1280,
              height: 1024,
            },
            { headers: headers }
          );
          resolve(response.data.data);
        } catch (err) {
          reject(err);
        }
      });
    },
    async OnInsertImg() {
      var imageInformation = {
        uuid: uuidv4(),
        centerX: 128,
        centerY: 128,
        width: 128,
        height: 128,
        locked: false,
      };
      this.room.insertImage(imageInformation);
      this.room.completeImageUpload(
        imageInformation.uuid,
        "https://d219j8to51rabe.cloudfront.net/02816f5b9496404f95ce51108b1317ea/screenshot/1634220757815.png"
      );
    },
    async OnReplay() {
      let startTime = new Date().getTime() - 10000;
      var replayRoomParams = {
        room: this.boardInfo.boardId,
        roomToken: this.boardInfo.boardToken,
        beginTimestamp: startTime,
        duration: 10 * 1000,
      };
      this.whiteWebSdk
        .replayRoom(replayRoomParams)
        .then((player) => {
          player.bindHtmlElement(this.$refs.replayboard);
          player.play();
        })
        .catch(function (err) {
          // 获取回放数据失败
          console.error(err);
        });
    },
    getBoardInfo() {
      return new Promise(async (resolve, reject) => {
        const url = `${this.apiUrl}/join`;
        try {
          const response = await axios.post(url, {
            roomId: this.roomId,
          });
          resolve(response.data.data);
        } catch (err) {
          reject(err);
        }
      });
    },
  },
};
</script>
<style scoped>
.whiteboard {
  width: 100%;
  height: 50vh;
  background: white;
}
.replayboard {
  width: 100%;
  height: 50vh;
}
</style>

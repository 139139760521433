<template>
  <div>
    <v-card class="mx-auto" tile>
      <v-list dense>
        <v-subheader></v-subheader>
        <v-btn @click="Login()">登入</v-btn>
      </v-list>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  async mounted() {},
  created() {},
  destroyed() {},
  methods: {
    Login() {
      window.BX24.init(function () {
        console.log('Init done!', BX24.isAdmin());
      });
    },
  },
};
</script>

<style scoped>
.remoteView {
  margin: 10px;
}
</style>
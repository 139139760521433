<template>
  <v-container id="sender" fluid fill-height class="ma-0 pa-0" ref="sender">
    
    <!-- <v-btn
      id="btn_fullscreen"
      elevation="2"
      fab
      small
      @click="fullscreenToggle()"
    >
      <v-icon>{{
        fullscreen ? `mdi-fullscreen-exit` : `mdi-fullscreen`
      }}</v-icon>
    </v-btn> -->
    <v-btn
      id="btn_switchDevice"
      @click="openDeviceDialog()"
      v-if="step == 3"
      elevation="2"
      fab
      small
      style="z-index: 10"
    >
      <v-icon>mdi-camera-switch-outline</v-icon>
    </v-btn>
    <v-btn
      id="btn_stopCanvas"
      v-if="step == 4"
      elevation="2"
      fab
      small
      @click="stopCanvas()"
      style="z-index: 10"
    >
      <v-icon>mdi-camera-outline</v-icon>
    </v-btn>
    <v-snackbar v-model="errorBar">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorBar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-col>
        <div v-if="step == 1">
          <v-container fill-height fluid>
            <v-row justify="space-around">
              <v-col class="d-flex justify-center">
                <v-card color="#51464c" elevation="0">
                  <v-card-title primary-title class="justify-center">
                    OneBook 遙控器</v-card-title
                  >
                  <v-card-actions class="justify-center">
                    <v-progress-circular
                      indeterminate
                      color="amber"
                    ></v-progress-circular>
                  </v-card-actions>

                  <v-card-subtitle class="text-center"
                    >裝置連線中...</v-card-subtitle
                  >
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-if="step == 2">
          <v-container fill-height fluid>
            <v-row justify="space-around">
              <v-col class="d-flex justify-center">
                <v-btn @click="openCamera()">實物投影</v-btn>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col class="d-flex justify-center">
                <v-btn @click="openBook()">同步遙控</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-show="step == 3">
          <v-layout d-flex justify-center align-center>
            <v-dialog v-model="videoDeviceDialog">
              <v-list>
                <v-subheader>請選擇裝置</v-subheader>
                <v-list-item-group v-model="videoDevices">
                  <v-list-item
                    v-for="videoDevice in videoDevices"
                    :key="videoDevice.deviceId"
                    @click="selectDevice(videoDevice.deviceId)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="videoDevice.label"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <!-- <v-list-item-icon>
                      <v-icon v-if="videoDevice.deviceId == videoDeviceId">
                        mdi-message-outline
                      </v-icon>
                    </v-list-item-icon> -->
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-dialog>
            <video ref="myVideo"></video>
            <v-progress-circular
              id="loading"
              indeterminate
              color="amber"
              v-if="isShowLoading"
            ></v-progress-circular>
            <v-btn
              id="btn_takePhoto"
              elevation="2"
              fab
              color="white"
              @click="takePhoto()"
            >
              <v-icon color="black">mdi-image-edit-outline</v-icon>
            </v-btn>

            <!-- <v-bottom-navigation
              background-color="transparent"
              color="rgba(0,0,0,0.5)"
              horizontal
              fixed
              grow
            >
              <v-btn
                @click="openDeviceDialog()"
                background-color="transparent"
                active-class="no-active"
              >
                <v-icon> mdi-camera-switch-outline</v-icon>
              </v-btn>
            </v-bottom-navigation> -->
          </v-layout>
        </div>
        <div v-show="step == 4">
          <v-layout justify-center align-center>
            <div style="position: relative">
              <canvas
                ref="drawCanvas"
                style="z-index: 1; position: absolute"
              ></canvas>
              <canvas ref="bgCanvas" style="z-index: 0"></canvas>
            </div>
            <img ref="myImg" v-show="false" />
            <canvas ref="streamCanvas" v-show="false"></canvas>
            <v-bottom-navigation
              background-color="transparent"
              color="rgba(0,0,0,0.5)"
              fixed
              horizontal
              grow
              active-class="no-active"
            >
              <v-menu
                v-model="colorMenu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    background-color="transparent"
                  >
                    <v-icon>mdi-palette-outline</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-color-picker v-model="penColor" hide-inputs>
                  </v-color-picker>
                  <!-- <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="colorMenu = false"
                    >
                      OK
                    </v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-menu>
              <v-menu
                v-model="lineWidthMenu"
                :close-on-content-click="false"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    background-color="transparent"
                  >
                    <v-icon>mdi-format-line-weight</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-slider
                    v-model="lineWidth"
                    vertical
                    min="1"
                    max="24"
                    thumb-label="always"
                    v-if="penMode == 'pen'"
                  >
                    <template v-slot:thumb-label="{ value }">
                      {{ value }}
                    </template>
                  </v-slider>
                  <v-slider
                    v-model="eraseWidth"
                    vertical
                    min="5"
                    max="24"
                    thumb-label="always"
                    v-if="penMode == 'eraser'"
                  >
                    <template v-slot:thumb-label="{ value }">
                      {{ value }}
                    </template>
                  </v-slider>
                </v-card>
              </v-menu>

              <v-btn background-color="transparent" @click="penMode = 'pen'">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn background-color="transparent" @click="penMode = 'eraser'">
                <v-icon>mdi-eraser-variant</v-icon>
              </v-btn>
              <v-btn
                background-color="transparent"
                @click="resetDrawDialog = true"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </v-bottom-navigation>

            <v-dialog v-model="resetDrawDialog" max-width="290">
              <v-card>
                <v-card-title class="headline">
                  是否刪除全部畫筆物件
                </v-card-title>
                <v-card-text>
                  是否確認刪除全部畫筆物件？<br />
                  *刪除後將不可復原，確定刪除請按確認。
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="resetDrawDialog = false"
                  >
                    取消
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="onClearDrawOkClick()"
                  >
                    確認刪除
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import fullscreen from "vue-fullscreen";
import Vue from "vue";
Vue.use(fullscreen);
import { io } from "socket.io-client";
import { Projection } from "../../common/meeting";
export default {
  data() {
    return {
      fullscreen: false,
      step: 1,
      penColor: "#ff0000",
      colorMenu: false,
      lineWidthMenu: false,
      videoDeviceDialog: false,
      resetDrawDialog: false,
      videoDevices: [],
      videoDeviceId: "",
      isShowLoading: true,
      penMode: "pen",
      lineWidth: 5,
      eraseWidth: 10,
      errorMsg: "",
      errorBar: false,
    };
  },
  components: {},
  created() {},
  mounted() {
    this.remoteId = this.$route.query.remoteId;
    if (!this.remoteId || this.remoteId.length == 0)
      this.$router.push({ name: "Home" });
    this.apiUrl = process.env.VUE_APP_API_URL;
    let socketUrl = process.env.VUE_APP_SOCKET_URL;
    this.mySocket = io(socketUrl, {
      path: "/socket",
      transports: ["websocket"],
      query: {
        remoteId: this.remoteId,
      },
    });
    this.mySocket.on("onConnection", () => {
      this.mySocket.emit("joinRemote", "");
      console.log("socket connection");

      this.mySocket.emit("ready", "");
    });

    this.mySocket.on("onReady", () => {
      this.openCamera();
    });

    this.mySocket.on("exitProjection", async () => {
      this.exit();
    });

    window.addEventListener("message", this.reset, false);

    this.bindCanvaDraw();
  },
  methods: {
    fullscreenToggle() {
      this.$fullscreen.toggle(this.$refs.sender, {
        wrap: false,
        callback: () => {
          this.fullscreen = !this.fullscreen;
        },
      });
    },
    onClearDrawOkClick() {
      let drawCanvas = this.$refs.drawCanvas;
      let ctx = drawCanvas.getContext("2d");
      ctx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      this.resetDrawDialog = false;
    },
    switchMode() {
      if (this.step == 3) this.takePhoto();
      else this.stopCanvas();
    },

    showError(msg) {
      (this.errorMsg = msg), (this.errorBar = true);
    },
    async openDeviceDialog() {
      this.videoDevices = await this.projection.GetVideoInputDevices();
      this.videoDeviceDialog = true;
    },
    async selectDevice(deviceId) {
      if (!!this.projection) {
        this.videoDeviceId = deviceId;
        await this.projection.SetVideoDevice(deviceId);
        this.videoDeviceDialog = false;
      }
    },
    async exit() {
      if (!!this.projection) {
        await this.projection.Leave();
        this.projection = null;
      }
      this.mySocket.emit("exitProjection", "");
      this.$sendPostMessage("HideWindow");
    },
    async reset(e) {
      this.step = 1;
      await this.openCamera();
    },
    async openCamera() {
      this.step = 3;
      if (!this.projection) {
        this.projection = new Projection({
          apiUrl: this.apiUrl,
          videoElement: this.$refs.myVideo,
        });
        let deviceErrorHandler = (errorCode) => {
          this.showError(`目前此裝置無法使用：錯誤碼 ${errorCode}`);
        };
        this.projection.SetDeviceErrorHandler(deviceErrorHandler);
        let customObserver = {
          videoTileDidUpdate: (tileState) => {
            this.isShowLoading = false;
          },
        };
        let externalData = {
          ExternalMeetingId: `rc-${this.remoteId}`,
        };
        await this.projection.Launch(externalData, customObserver);
      }
      this.videoDevices = await this.projection.GetVideoInputDevices();
      if (!!this.videoDevices && this.videoDevices.length > 0)
        this.videoDeviceId = this.videoDevices[0].deviceId;
      let meeting = this.projection.GetMeeting();
      console.info(meeting);
      this.mySocket.emit("openProjection", {
        meetingId: meeting.MeetingId,
      });
    },
    async openBook() {
      this.mySocket.emit("openBook");
    },
    async sendCanvas() {
      let canvasElement = this.$refs.streamCanvas;
      var stream = canvasElement.captureStream(10);
      this.projection.SetVideoDevice(null);
      await this.projection.StartContentShare(stream);
    },

    async stopCanvas() {
      this.step = 3;
      await this.projection.StopContentShare();
      this.projection.SetVideoDevice(this.videoDeviceId);
    },

    async takePhoto() {
      let streamCanvas = this.$refs.streamCanvas;
      let video = this.$refs.myVideo;
      streamCanvas.height = video.videoHeight;
      streamCanvas.width = video.videoWidth;

      var streamCtx = streamCanvas.getContext("2d");
      streamCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      this.$refs.myImg.src = streamCanvas.toDataURL();

      let drawCanvas = this.$refs.drawCanvas;
      let bgCanvas = this.$refs.bgCanvas;
      let drawWidth, drawHeight, drawX, drawY;
      if (
        window.innerHeight / window.innerWidth >
        streamCanvas.height / streamCanvas.width
      ) {
        drawWidth = window.innerWidth;
        drawHeight =
          window.innerWidth *
          parseFloat(streamCanvas.height / streamCanvas.width);
      } else {
        drawHeight = window.innerHeight;
        drawWidth =
          window.innerHeight *
          parseFloat(streamCanvas.width / streamCanvas.height);
      }

      drawCanvas.height = drawHeight;
      drawCanvas.width = drawWidth;
      bgCanvas.height = drawHeight;
      bgCanvas.width = drawWidth;
      var drawCtx = drawCanvas.getContext("2d");
      var bgCtx = bgCanvas.getContext("2d");
      bgCtx.drawImage(
        streamCanvas,
        0,
        0,
        streamCanvas.width,
        streamCanvas.height,
        0,
        0,
        drawWidth,
        drawHeight
      );

      drawCtx.clearRect(0, 0, drawCanvas.width, drawCanvas.height);
      await this.sendCanvas();

      this.syncStreamCanvas();

      this.step = 4;
    },
    syncStreamCanvas() {
      let streamCanvas = this.$refs.streamCanvas;
      let drawCanvas = this.$refs.drawCanvas;
      let streamCtx = streamCanvas.getContext("2d");
      streamCtx.drawImage(
        this.$refs.myImg,
        0,
        0,
        this.$refs.myImg.width,
        this.$refs.myImg.height
      );
      streamCtx.drawImage(
        drawCanvas,
        0,
        0,
        drawCanvas.width,
        drawCanvas.height,
        0,
        0,
        streamCanvas.width,
        streamCanvas.height
      );
      window.requestAnimationFrame(this.syncStreamCanvas);
    },
    async bindCanvaDraw() {
      let self = this;
      // Canvas DOM 元素
      const drawCanvas = this.$refs.drawCanvas;
      const ctx = drawCanvas.getContext("2d");
      ctx.globalCompositeOperation = "destination-over";

      //起始點座標
      let x1 = 0;
      let y1 = 0;

      // 終點座標
      let x2 = 0;
      let y2 = 0;

      //宣告一個 hasTouchEvent 變數，來檢查是否有 touch 的事件存在
      const hasTouchEvent = "ontouchstart" in window ? true : false;
      // 透過上方的 hasTouchEvent 來決定要監聽的是 mouse 還是 touch 的事件
      const downEvent = hasTouchEvent ? "touchstart" : "mousedown";
      const moveEvent = hasTouchEvent ? "touchmove" : "mousemove";
      const upEvent = hasTouchEvent ? "touchend" : "mouseup";

      // 宣告 isMouseActive 為滑鼠點擊的狀態，因為我們需要滑鼠在 mousedown 的狀態時，才會監聽 mousemove 的狀態
      let isMouseActive = false;

      drawCanvas.addEventListener(downEvent, function (e) {
        isMouseActive = true;
        if (hasTouchEvent) {
          let r = drawCanvas.getBoundingClientRect();
          x1 = e.touches[0].clientX - r.left;
          y1 = e.touches[0].clientY - r.to;
        } else {
          x1 = e.offsetX;
          y1 = e.offsetY;
        }
        ctx.strokeStyle = self.penColor;
        ctx.lineWidth = self.lineWidth;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
      });

      drawCanvas.addEventListener(moveEvent, function (e) {
        if (!isMouseActive) {
          return;
        }
        // 取得終點座標
        if (hasTouchEvent) {
          let r = drawCanvas.getBoundingClientRect();
          x2 = e.touches[0].clientX - r.left;
          y2 = e.touches[0].clientY - r.top;
        } else {
          x2 = e.offsetX;
          y2 = e.offsetY;
        }

        if (self.penMode === "pen") {
          ctx.strokeStyle = self.penColor;
          ctx.lineWidth = self.lineWidth;
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(x2, y2);
          ctx.stroke();
        } else if (self.penMode === "eraser") {
          ctx.clearRect(x1, y1, self.eraseWidth, self.eraseWidth);
        }

        // 更新起始點座標
        x1 = x2;
        y1 = y2;

        e.preventDefault();
      });

      drawCanvas.addEventListener(upEvent, function (e) {
        isMouseActive = false;
      });
    },
  },
};
</script>
<style scoped>
canvas {
  background-color: transparent;
}
#sender {
  background-color: black;
}

#btn_exit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}
#btn_fullscreen {
  position: absolute;
  top: 10px;
  right: 60px;
  z-index: 100;
}
#btn_switchDevice {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}

#btn_takePhoto {
  position: absolute;
  bottom: 50px;
  z-index: 100;
}

#btn_stopCanvas {
  position: absolute;
  top: 10px;
  left: 10px;
}

video {
  width: 100vw;
  height: 100vh;
}

#loading {
  position: absolute;
}
#btn-takephoto {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: "translateX(-50%)";
  color: #ec7963;
  background: white;
}

.v-item-group.v-bottom-navigation .v-btn.v-btn--active {
  color: rgba(255, 255, 255, 0.7);
}
</style>
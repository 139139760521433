<template>
  <div>
    <v-card class="mx-auto" tile>
      <v-list dense>
        <v-subheader>通訊中清單</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(item, key) in callingMembers"
            :key="key"
            @click="playItem(item)"
          >
            <v-list-item-content>
              <v-row>
                <v-col> {{ item.UserName }} </v-col>
                <v-col> {{ item.CreateTime }} </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <audio class="remoteView" ref="remoteView" autoplay="autoplay" controls />
    <audio ref="localView" autoplay="autoplay" muted />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
import { Role, SignalingClient } from "amazon-kinesis-video-streams-webrtc";
const viewer = {};

export default {
  data() {
    return {
      teamId: null,
      region: "ap-northeast-1",
      credentials: null,
      kinesisVideoClient: null,
      callings: [],
      selectedItem: null,
      clientId: null,
      credentials: null,
      teamMembers: [],
    };
  },
  components: {},
  computed: {
    memberIds: function () {
      return this.teamMembers.map((p) => p.id);
    },
    callingMembers: function () {
      return this.callings.filter((p) => this.memberIds.contains(p.userId));
    },
  },
  async mounted() {
    this.teamId = this.getTeamId(this.$route.params.teamName);
    this.teamMembers = await this.getMembers();
    this.clientId = uuidv4();
    this.credentials = await this.fetchCredentials();
    this.kinesisVideoClient = new AWS.KinesisVideo({
      region: this.region,
      credentials: this.credentials,
      correctClockSkew: true,
    });
    this.getList();
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    this.websocket.close(); //离开路由之后断开websocket连接
  },
  methods: {
    async getMembers() {
      const res = await axios.get(
        `https://3xcb7p2drj.execute-api.ap-northeast-1.amazonaws.com/Prod/sip/get_dept_members?deptId=${this.teamId}`
      );
      return res.data.data;
    },
    getTeamId(teamName) {
      switch (teamName) {
        case "team1":
          return 134;
        case "team2":
          return 136;
        case "team3":
          return 138;
        case "team4":
          return 140;
        default:
          return null;
      }
    },
    initWebSocket() {
      //初始化weosocket
      const wsuri = `wss://sp2b0yjlng.execute-api.ap-northeast-1.amazonaws.com/Prod`;
      this.websocket = new WebSocket(wsuri);
      this.websocket.onerror = this.onError;
      this.websocket.onmessage = this.onMessage;
    },
    onError(ev) {
      this.initWebSocket();
    },
    onMessage() {
      this.getList();
    },
    async getList() {
      const listSignalingChannelsResponse = await this.kinesisVideoClient
        .listSignalingChannels({
          MaxResults: 500,
        })
        .promise();
      const channelInfos = listSignalingChannelsResponse.ChannelInfoList;
      if (!!channelInfos) {
        channelInfos.forEach(async (channleInfo) => {
          await this.addItem(channleInfo);
        });
      }
    },
    async addItem(channleInfo) {
      if (
        this.callings.some(
          (item) => item.ChannelName === channleInfo.channleName
        )
      )
        return;
      const listTagsForResourceResponse = await this.kinesisVideoClient
        .listTagsForResource({
          ResourceARN: channleInfo.ChannelARN,
        })
        .promise();
      const tags = listTagsForResourceResponse.Tags;
      if (!!tags) {
        this.callings.push({
          UserName: tags.UserName,
          UserId: tags.UserId,
          CreateTime: channleInfo.CreationTime,
          ChannelARN: channleInfo.ChannelARN,
        });
      }
    },
    async playItem(item) {
      const formValue = {
        region: this.region,
        channelARN: item.ChannelARN,
        credentials: this.credentials,
        clientId: this.clientId,
        sendVideo: true,
        sendAudio: true,
        openDataChannel: false,
        widescreen: false,
        useTrickleICE: true,
        forceTURN: false,
      };
      this.startViewer(this.$refs.remoteView, formValue);
    },
    async fetchCredentials() {
      const response = await fetch(
        `https://3xcb7p2drj.execute-api.ap-northeast-1.amazonaws.com/Prod/sip/fetch_credentials`,
        {
          method: "GET",
        }
      );
      const json = await response.json();
      if (json.error) {
        throw new Error(`Server error: ${json.error}`);
      }
      return json;
    },
    async startViewer(remoteView, formValues) {
      //  viewer.localView = localView;
      viewer.remoteView = remoteView;

      // Create KVS client
      const kinesisVideoClient = new AWS.KinesisVideo({
        region: formValues.region,
        credentials: formValues.credentials,
        correctClockSkew: true,
      });

      // Get signaling channel ARN
      // const describeSignalingChannelResponse = await kinesisVideoClient
      //   .describeSignalingChannel({
      //     ChannelARN: formValues.channelARN,
      //   })
      //   .promise();
      // const channelARN =
      //   describeSignalingChannelResponse.ChannelInfo.ChannelARN;
      console.log("[VIEWER] Channel ARN: ", formValues.channelARN);

      // Get signaling channel endpoints
      const getSignalingChannelEndpointResponse = await kinesisVideoClient
        .getSignalingChannelEndpoint({
          ChannelARN: formValues.channelARN,
          SingleMasterChannelEndpointConfiguration: {
            Protocols: ["WSS", "HTTPS"],
            Role: Role.VIEWER,
          },
        })
        .promise();
      const endpointsByProtocol =
        getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
          (endpoints, endpoint) => {
            endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
            return endpoints;
          },
          {}
        );
      console.log("[VIEWER] Endpoints: ", endpointsByProtocol);

      const kinesisVideoSignalingChannelsClient =
        new AWS.KinesisVideoSignalingChannels({
          region: formValues.region,
          credentials: formValues.credentials,
          endpoint: endpointsByProtocol.HTTPS,
          correctClockSkew: true,
        });

      // Get ICE server configuration
      const getIceServerConfigResponse =
        await kinesisVideoSignalingChannelsClient
          .getIceServerConfig({
            ChannelARN: formValues.channelARN,
          })
          .promise();
      const iceServers = [];
      if (!formValues.natTraversalDisabled && !formValues.forceTURN) {
        iceServers.push({
          urls: `stun:stun.kinesisvideo.${formValues.region}.amazonaws.com:443`,
        });
      }
      if (!formValues.natTraversalDisabled) {
        getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
          iceServers.push({
            urls: iceServer.Uris,
            username: iceServer.Username,
            credential: iceServer.Password,
          })
        );
      }
      console.log("[VIEWER] ICE servers: ", iceServers);

      // Create Signaling Client
      viewer.signalingClient = new SignalingClient({
        channelARN: formValues.channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        clientId: formValues.clientId,
        role: Role.VIEWER,
        region: formValues.region,
        credentials: formValues.credentials,
        systemClockOffset: kinesisVideoClient.config.systemClockOffset,
      });

      const constraints = {
        video: false,
        audio: true,
      };
      const configuration = {
        iceServers,
        iceTransportPolicy: formValues.forceTURN ? "relay" : "all",
      };
      viewer.peerConnection = new RTCPeerConnection(configuration);
      if (formValues.openDataChannel) {
        viewer.dataChannel =
          viewer.peerConnection.createDataChannel("kvsDataChannel");
        viewer.peerConnection.ondatachannel = () => {
          //       event.channel.onmessage = onRemoteDataMessage;
        };
      }

      // Poll for connection stats
      // viewer.peerConnectionStatsInterval = setInterval(
      //   () => viewer.peerConnection.getStats().then(onStatsReport),
      //   1000
      // );

      viewer.signalingClient.on("open", async () => {
        console.log("[VIEWER] Connected to signaling service");

        // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
        // If no video/audio needed, no need to request for the sources.
        // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
        if (formValues.sendVideo || formValues.sendAudio) {
          try {
            viewer.localStream = await navigator.mediaDevices.getUserMedia(
              constraints
            );
            viewer.localStream
              .getTracks()
              .forEach((track) =>
                viewer.peerConnection.addTrack(track, viewer.localStream)
              );
            //    localView.srcObject = viewer.localStream;
          } catch (e) {
            console.error("[VIEWER] Could not find webcam");
            return;
          }
        }

        // Create an SDP offer to send to the master
        console.log("[VIEWER] Creating SDP offer");
        await viewer.peerConnection.setLocalDescription(
          await viewer.peerConnection.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          })
        );

        // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        if (formValues.useTrickleICE) {
          console.log("[VIEWER] Sending SDP offer");
          viewer.signalingClient.sendSdpOffer(
            viewer.peerConnection.localDescription
          );
        }
        console.log("[VIEWER] Generating ICE candidates");
      });

      viewer.signalingClient.on("sdpAnswer", async (answer) => {
        // Add the SDP answer to the peer connection
        console.log("[VIEWER] Received SDP answer");
        await viewer.peerConnection.setRemoteDescription(answer);
      });

      viewer.signalingClient.on("iceCandidate", (candidate) => {
        // Add the ICE candidate received from the MASTER to the peer connection
        console.log("[VIEWER] Received ICE candidate");
        viewer.peerConnection.addIceCandidate(candidate);
      });

      viewer.signalingClient.on("close", () => {
        console.log("[VIEWER] Disconnected from signaling channel");
      });

      viewer.signalingClient.on("error", (error) => {
        console.error("[VIEWER] Signaling client error: ", error);
      });

      // Send any ICE candidates to the other peer
      viewer.peerConnection.addEventListener(
        "icecandidate",
        ({ candidate }) => {
          if (candidate) {
            console.log("[VIEWER] Generated ICE candidate");

            // When trickle ICE is enabled, send the ICE candidates as they are generated.
            if (formValues.useTrickleICE) {
              console.log("[VIEWER] Sending ICE candidate");
              viewer.signalingClient.sendIceCandidate(candidate);
            }
          } else {
            console.log("[VIEWER] All ICE candidates have been generated");

            // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
            if (!formValues.useTrickleICE) {
              console.log("[VIEWER] Sending SDP offer");
              viewer.signalingClient.sendSdpOffer(
                viewer.peerConnection.localDescription
              );
            }
          }
        }
      );

      // As remote tracks are received, add them to the remote view
      viewer.peerConnection.addEventListener("track", (event) => {
        console.log("[VIEWER] Received remote track");
        if (remoteView.srcObject) {
          return;
        }
        viewer.remoteStream = event.streams[0];
        remoteView.srcObject = viewer.remoteStream;
      });

      console.log("[VIEWER] Starting viewer connection");
      viewer.signalingClient.open();
    },
    stopViewer() {
      console.log("[VIEWER] Stopping viewer connection");
      if (viewer.signalingClient) {
        viewer.signalingClient.close();
        viewer.signalingClient = null;
      }

      if (viewer.peerConnection) {
        viewer.peerConnection.close();
        viewer.peerConnection = null;
      }

      if (viewer.localStream) {
        viewer.localStream.getTracks().forEach((track) => track.stop());
        viewer.localStream = null;
      }

      if (viewer.remoteStream) {
        viewer.remoteStream.getTracks().forEach((track) => track.stop());
        viewer.remoteStream = null;
      }

      if (viewer.peerConnectionStatsInterval) {
        clearInterval(viewer.peerConnectionStatsInterval);
        viewer.peerConnectionStatsInterval = null;
      }

      if (viewer.localView) {
        viewer.localView.srcObject = null;
      }

      if (viewer.remoteView) {
        viewer.remoteView.srcObject = null;
      }

      if (viewer.dataChannel) {
        viewer.dataChannel = null;
      }
    },
    sendViewerMessage(message) {
      if (viewer.dataChannel) {
        try {
          viewer.dataChannel.send(message);
        } catch (e) {
          console.error("[VIEWER] Send DataChannel: ", e.toString());
        }
      }
    },
  },
};
</script>

<style scoped>
.remoteView {
  margin: 10px;
}
</style>
<template>
  <v-container>
    <v-row>
      <v-col v-for="item in items" :key="item.id">
        <signle :record="item" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import videojs from "video.js";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import signle from "../components/signle.vue";
// @ is an alias to /src
export default {
  name: "Hls",
  components: {
    signle,
  },
  data() {
    return {
      interval: null,
      items: [],
    };
  },
  created() {},
  async mounted() {
    this.interval = window.setInterval(this.getRecords, 30000);
    this.getRecords();
  },
  beforeDestroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    async getRecords() {
      const url = `https://4ic5gz9bzk.execute-api.ap-northeast-1.amazonaws.com/Prod/agora/records`;
      try {
        const response = await axios.get(url);
        let orgItems = response.data.data;
        orgItems.sort((a, b) => {
          return a.startTime > b.startTime ? 1 : -1;
        });
        this.items = orgItems;
      } catch (err) {}
    },
  },
};
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
}
</style>

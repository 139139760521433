import axios from 'axios'
export default class Api {
    constructor(options = {}) {
        let beseUrl = options.apiUrl || "https://oneclick.oneclass.com.tw/api/Chime";

        this.instance = axios.create({
            baseURL: beseUrl
        });
    }

    createMeeting(externalData) {
        return new Promise((resolve, reject) => {
            this.instance.post("/Meetings", externalData)
                .then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
        });
    }

    getMeetingList() {
        return new Promise((resolve, reject) => {
            this.instance.get("/Meetings")
                .then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
        });
    }

    getMeeting(meetingId) {
        return new Promise((resolve, reject) => {
            this.instance.get("/Meetings/" + meetingId)
                .then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
        });
    }

    deleteMeeting(meetingId) {
        return new Promise((resolve, reject) => {
            this.instance.delete("/Meetings/" + meetingId)
                .then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    console.log(err);
                    reject(err)
                })
        });
    }

    getAttendeeInfo(meetingId, attendeeId) {
        return new Promise((resolve, reject) => {
            this.instance.get(`/Meetings/${meetingId}/Attendees/${attendeeId}`)
                .then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    reject(err)
                })
        });
    }

    addAttendee(meetingId, username) {
        return new Promise((resolve, reject) => {
            this.instance.post("/Meetings/" + meetingId + "/Attendees", {
                username: username,
            }).then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        });
    }
}
<template>
  <div>
    <v-card class="mx-auto" tile>
      <v-list dense>
        <v-subheader></v-subheader>
        <v-file-input v-model="myFile" truncate-length="15"></v-file-input>
        <v-btn @click="onFileSendClick()">上傳</v-btn>
      </v-list>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
export default {
  data() {
    return {
      myFile: null,
      region: 'ap-northeast-1',
      credentials: null,
      kinesisVideoClient: null,
      callings: [],
      selectedItem: null,
      clientId: null,
      credentials: null,
      teamMembers: [],
    };
  },
  components: {},
  computed: {},
  async mounted() {},
  created() {},
  destroyed() {},
  methods: {
    async onFileSendClick() {
      const file = this.myFile;
      console.log(file);
      let fileId = await this.checkFile(file.name);
      console.log(fileId);
      let uploadId = await this.initUpload(fileId);
      console.log(uploadId);
      let results = await this.uploadFile(file, fileId, uploadId);
      await this.finishedUpload(fileId, uploadId, results);
    },

    async checkFile(filename) {
      var checkFileRequest = {
        uploadOption: {
          schoolYear: '110',
          edu: 'E',
          subject: 'MA',
          version: 'N',
          book: 'B01',
        },
        fileNames: [filename],
      };
      var res = await fetch('http://onetube-api-dev.oneclass.com.tw/api/Stored/CheckFile', {
        body: JSON.stringify(checkFileRequest),
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
      }).then(response => response.json());

      return res.data[0].fileId;
    },

    async initUpload(fileId) {
      var initUploadRequest = {
        fileId: fileId,
      };
      var res = await fetch('http://onetube-api-dev.oneclass.com.tw/api/Stored/Upload/Init', {
        body: JSON.stringify(initUploadRequest),
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST',
      }).then(response => response.json());
      return res.data.uploadId;
    },

    async uploadFile(file, fileId, uploadId) {
      const chunkSize = 10 * 1024 * 1024; //10MB

      const blobSlice =
        File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;

      const blockCount = Math.ceil(file.size / chunkSize);
      const resultMap = [];
      for (let i = 0; i < blockCount; i++) {
        const start = i * chunkSize;
        const end = Math.min(file.size, start + chunkSize);
        // Building forms
        let data = blobSlice.call(file, start, end);
        const form = new FormData();
        form.append('file', data);
        form.append('fileId', fileId);
        form.append('partSize', blockCount);
        form.append('partNumber', i + 1);
        form.append('uploadId', uploadId);

        var res = await fetch('http://onetube-api-dev.oneclass.com.tw/api/Stored/Upload/Complete', {
          body: JSON.stringify(finishedFileRequest), // must match 'Content-Type' header
          headers: {
            'content-type': 'application/json',
          },
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
        }).then(response => response.json());

        var res = await axios.post('http://onetube-api-dev.oneclass.com.tw/Stored/Upload', form);
        console.log(res.data.data);
        resultMap.push(res.data.data);
      }
      return resultMap;
    },

    async finishedUpload(fileId, uploadId, results) {
      var finishedFileRequest = {
        fileId: fileId,
        uploadId: uploadId,
        partResults: results,
      };
      var res = await fetch('http://onetube-api-dev.oneclass.com.tw/api/Stored/Upload/Complete', {
        body: JSON.stringify(finishedFileRequest), // must match 'Content-Type' header
        headers: {
          'content-type': 'application/json',
        },
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
      }).then(response => response.json());
    },
  },
};
</script>

<style scoped>
.remoteView {
  margin: 10px;
}
</style>
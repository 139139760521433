<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row></v-row>
    <!-- <v-row>
      <v-col class="col-1"> </v-col>
      <v-col>
        <v-text-field
          class="txtUrl"
          v-model="txtUrl"
          label="目標網址"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col class="col-1"> </v-col>
    </v-row> -->
    <v-row>
      <v-col class="col-1"> </v-col>
      <v-col> 房間號:{{ this.cname }} </v-col>
      <v-col>{{ time }}ms </v-col>
      <v-col class="col-1"> </v-col>
    </v-row>
    <!-- <v-row>
      <v-col class="d-flex justify-center">
        <v-btn class="btn ma-5" depressed @click="startRecord">
          開始錄影
        </v-btn>
        <v-btn class="btn ma-5" depressed @click="stopRecord"> 停止錄影 </v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col class="d-flex justify-center">
        <video
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered"
          controls
        ></video>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import videojs from "video.js";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// @ is an alias to /src
export default {
  name: "Hls",
  components: {},
  data() {
    return {
      cname: "",
      txtUrl: "",
      startTime: new Date(),
      endTime: new Date(),
      timer: null,
    };
  },
  created() {},
  async mounted() {
    this.player = videojs(this.$refs.videoPlayer, { liveui: true });
    this.player.ready(() => {
      this.player.play();
    });
    this.cname = this.$route.params.cname;
    if (!!this.cname) this.tryGetHLS();
    else this.cname = uuidv4().replace(/-/g, "");
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  computed: {
    time: function () {
      return this.endTime - this.startTime;
    },
    apiUrl: function () {
      // if (process.env.NODE_ENV === "development")
      //   return `https://9q1rtjdc0j.execute-api.ap-northeast-1.amazonaws.com/Prod/agora`;
      // else
      return `https://4ic5gz9bzk.execute-api.ap-northeast-1.amazonaws.com/Prod/agora`;
    },
  },
  methods: {
    playHLS(mediaUrl) {
      this.player.src({ src: mediaUrl });
    },
    async tryGetHLS() {
      await this.getHLSUrl()
        .then((res) => {
          clearInterval(this.timer);
          this.endTime = new Date();
          this.playHLS(res);
        })
        .catch((err) => {
          setTimeout(this.tryGetHLS, 2000);
        });
    },
    getHLSUrl() {
      return new Promise(async (resolve, reject) => {
        const url = `${this.apiUrl}/get_media_url?roomId=${this.cname}`;
        try {
          const response = await axios.get(url);
          resolve(response.data.hls);
        } catch (err) {
          reject(err);
        }
      });
    },
    async startRecord() {
      const encoudUrl = encodeURIComponent(this.txtUrl);
      const url = `${this.apiUrl}/start_record`;
      const response = await axios.post(url, {
        roomId: this.cname,
        recordurl: this.txtUrl,
      });
      this.startTime = new Date();
      this.endTime = new Date();
      this.timer = setInterval(this.countdown, 300);
      this.tryGetHLS();
    },
    async stopRecord() {
      let yes = confirm(`是否要停止錄影${this.cname}?`);

      if (yes) {
        const url = `${this.apiUrl}/stop_record`;
        const response = await axios.post(url, {
          roomId: this.cname,
        });
      }
    },
    countdown() {
      this.endTime = new Date();
    },
  },
};
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
}
</style>

import {
    ConsoleLogger,
    DefaultDeviceController,
    DefaultMeetingSession,
    // DefaultActiveSpeakerPolicy,
    DefaultVideoTile,
    LogLevel,
    DefaultModality,
    MeetingSessionConfiguration,
    PermissionDeniedError,
    NotReadableError,
    GetUserMediaError,
    NotFoundError,
    OverconstrainedError,
    TypeError
} from "amazon-chime-sdk-js";

import Api from "./api"


export class Projection {
    constructor(options = {}) {
        this.api = new Api({ apiUrl: options.apiUrl })
        this.videoElement = options.videoElement;
        this.audioElement = options.audioElement;
    }

    GetMeeting() {
        return this.meetingResponse;
    }

    //啟動實物投影
    async Launch(externalData, observer) {

        this.meetingResponse = await this.api.createMeeting(externalData);
        this.attendeeResponse = await this.api.addAttendee(this.meetingResponse.MeetingId, "控制器");

        await this.#initChime(this.meetingResponse, this.attendeeResponse);
        if (!!observer) {
            await this.meetingSession.audioVideo.addObserver(observer);
        }
        this.videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
        await this.#bindLocalVideo();
        if (this.videoInputDevices != null && this.videoInputDevices.length > 0) {
            await this.SetVideoDevice(this.videoInputDevices[0].deviceId);
        }
    }
    async GetVideoInputDevices() {
        return this.videoInputDevices;
    }

    async SetDeviceErrorHandler(deviceErrorHandler) {
        this.deviceErrorHandler = deviceErrorHandler;
    }

    async SetVideoDevice(inputVideoDeviceId) {
        if (!!inputVideoDeviceId) {
            try {
                //先關閉再開啟
                // await this.meetingSession.audioVideo.chooseVideoInputDevice(
                //     null
                // );
                await this.meetingSession.audioVideo.chooseVideoInputDevice(
                    inputVideoDeviceId
                );
                this.inputVideoDeviceId = inputVideoDeviceId;
                this.meetingSession.audioVideo.startLocalVideoTile();
                console.log(this.meetingSession.audioVideo.getVideoInputQualitySettings());
            } catch (e) {
                console.log(e);
                if (!!this.deviceErrorHandler) {
                    this.deviceErrorHandler(this.getDeviceErrorCode(e));
                }
            }
        }
        else {
            await this.meetingSession.audioVideo.chooseVideoInputDevice(null);
            this.meetingSession.audioVideo.stopLocalVideoTile();
        }
    }

    getDeviceErrorCode(e) {
        console.log(e);
        if (e instanceof PermissionDeniedError)
            return 1;
        else if (e instanceof NotReadableError)
            return 2;
        else if (e instanceof GetUserMediaError)
            return 3;
        else if (e instanceof NotFoundError)
            return 4;
        else if (e instanceof OverconstrainedError)
            return 5;
        else if (e instanceof TypeError)
            return 6;
        return 9;
    }


    async addObserver(observer) {
        await this.meetingSession.audioVideo.addObserver(observer);
    }

    //投影
    async StartContentShare(mediaSream) {
        let contentShareStream = await this.meetingSession.audioVideo.startContentShare(mediaSream);
        DefaultVideoTile.connectVideoStreamToVideoElement(contentShareStream, this.videoElement, false);
    }

    async StartScreenShare() {
        let contentShareStream = await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
        DefaultVideoTile.connectVideoStreamToVideoElement(contentShareStream, this.videoElement, false);
    }

    async StopContentShare() {
        await this.meetingSession.audioVideo.stopContentShare();
    }

    //接收實物投影
    async Recevie(meetingId) {

        this.meetingResponse = await this.api.getMeeting(meetingId);
        this.attendeeResponse = await this.api.addAttendee(meetingId, "接收器");
        await this.#initChime(this.meetingResponse, this.attendeeResponse);

        await this.#bindRemoteVideo();
    }

    async Leave() {
        this.meetingSession.audioVideo.stop();
    }



    #initChime = async (meetingResponse, attendeeResponse) => {

        const logger = new ConsoleLogger("MyLogger", LogLevel.OFF);
        const deviceController = new DefaultDeviceController(logger);

        const configuration = new MeetingSessionConfiguration(
            meetingResponse,
            attendeeResponse
        );

        // configuration.enableUnifiedPlanForChromiumBasedBrowsers = true;
        // configuration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = true;

        // In the usage examples below, you will use this meetingSession object.
        this.meetingSession = new DefaultMeetingSession(
            configuration,
            logger,
            deviceController
        );

        //當任何參加者離開會議，則刪除此會議
        const callback = async (presentAttendeeId, present) => {
            //           const baseAttendeeId = new DefaultModality(presentAttendeeId).base();
            const baseAttendeeId = new DefaultModality(presentAttendeeId).base();
            if (baseAttendeeId != presentAttendeeId) //為內容分享
                return;

            if (!present) {
                await this.api.deleteMeeting(this.meetingResponse.MeetingId);
            }
        };
        this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(callback);


        // const observer = {
        //     audioVideoDidStop: sessionStatus => {

        //     }
        // };

        // this.meetingSession.audioVideo.addObserver(observer);

        //2021-03-18測試1289x720 高於預設值960x540，iphone會無法收到串流
        this.meetingSession.audioVideo.chooseVideoInputQuality(960, 540, 15, 1400);

        this.meetingSession.audioVideo.start();
    }

    #bindLocalVideo = async () => {
        const observer = {
            videoTileDidUpdate: (tileState) => {
                // Ignore a tile without attendee ID and other attendee's tile.
                if (!tileState.boundAttendeeId || !tileState.localTile) {
                    return;
                }
                if (!tileState.isContent) {
                    this.tileState = tileState;
                }

                this.meetingSession.audioVideo.bindVideoElement(
                    tileState.tileId,
                    this.videoElement
                );
            },
        };
        this.meetingSession.audioVideo.addObserver(observer);

    }

    #bindRemoteVideo = () => {

        const observer = {
            videoTileDidUpdate: (tileState) => {
                // Ignore a tile without attendee ID, a local tile (your video),
                if (!tileState.boundAttendeeId || tileState.localTile) {
                    return;
                }

                const boundAttendeeId = tileState.boundAttendeeId;

                const baseAttendeeId = new DefaultModality(boundAttendeeId).base();

                if (tileState.isContent) {
                    this.contentTileId = tileState.tileId;
                    this.meetingSession.audioVideo.unbindVideoElement(
                        this.senderTileId
                    );

                }
                else {
                    this.senderTileId = tileState.tileId;
                }
                if (tileState.isContent || !this.contentTileId) {
                    this.meetingSession.audioVideo.bindVideoElement(
                        tileState.tileId,
                        this.videoElement
                    );
                }
            },
            videoTileWasRemoved: (tileId) => {
                if (this.contentTileId == tileId) {
                    this.contentTileId = null;
                    //復原原實物投影影片
                    this.meetingSession.audioVideo.bindVideoElement(
                        this.senderTileId,
                        this.videoElement
                    );
                }
            },
        };

        this.meetingSession.audioVideo.addObserver(observer);
        const contentShareObserver = {
            contentShareDidStart: () => {
                console.log("Screen share started");
            },
            contentShareDidStop: () => {
                // Chime SDK allows 2 simultaneous content shares per meeting.
                // This method will be invoked if two attendees are already sharing content
                // when you call startContentShareFromScreenCapture or startContentShare.
                console.log("Screen share stopped");
            },
        };

        this.meetingSession.audioVideo.addContentShareObserver(
            contentShareObserver
        );
        this.meetingSession.audioVideo.addObserver(contentShareObserver);

    }
}
